import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'
import MenuImg from '../../assets/images/right_menu_table.png'
import instagram_icon from '../../assets/images/instagram_icon.png'
import facebook_icon from '../../assets/images/facebook_icon.png'


function Main() {
    const [active, setActive] = useState();
    const [Nav, setNav] = useState(false);
    const [Home, setHome] = useState();
    const [menuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const handleMenuItemClick = () => {
        closeMenu();
        setIsDropdownOpen(!isDropdownOpen);
    };
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {active &&
                <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }}></div>
            }
            <header className={`fixed ${menuOpen ? 'menu-open' : ''} ${isSticky ? 'header-triggered' : ''}`}>
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg">
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} alt="image" />
                        </Link>
                                    <div className="social-icons">
                <a href="https://www.instagram.com/organizasyonseramoni/" target="_blank" rel="noopener noreferrer">
                    <img src={instagram_icon} alt="Instagram Icon" width="40" className="instagram-icon" />
                </a>
                {/* <a href="https://www.facebook.com/denizatifethiye" target="_blank" rel="noopener noreferrer">
                    <img src={facebook_icon} alt="Facebook Icon" width="40" className="facebook-icon" />
                </a> */}
            </div>

                        <button className={`navbar-toggler ${menuOpen ? 'open' : ''}`} type="button" onClick={toggleMenu}>
                            <span className="navbar-toggler-icon" onClick={() => setHome(Home === true ? false : true)}>
                                <span className={`toggle-wrap ${menuOpen ? "active" : ""}`}>
                                    <span className="toggle-bar"></span>
                                </span>
                            </span>
                        </button>
                        <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/" onClick={handleMenuItemClick}>
                                        Anasayfa
                                    </Link>
                                </li>
                                {/* <li className="nav-item has_dropdown">
                                    <Link className="nav-link" to="#" onClick={handleMenuItemClick}>
                                        MENU{" "}
                                    </Link>
                                    <span className="drp_btn">
                                        <i className="icofont-rounded-down" />
                                    </span>
                                    <div className={`sub_menu ${isDropdownOpen ? 'open' : ''}`}>
                                        <ul style={{ display: "block" }}>
                                            <li>

                                                <Link to="/menulistone">Menu List 1</Link>
                                            </li>
                                            <li>
                                                <Link to="/menulisttwo">Menu List 2</Link>
                                            </li>
                                            <li>
                                                <Link to="/menulistthree">Menu List 3</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/menulisttwo" onClick={handleMenuItemClick}>
                                        Menü
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/aboutus" onClick={handleMenuItemClick}>
                                        Hakkımızda
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/ourchef" onClick={handleMenuItemClick}>
                                        BİZDEN
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/gallery" onClick={handleMenuItemClick}>
                                        GALERİ
                                    </Link>
                                </li>
                                {/* <li className="nav-item has_dropdown">
                                    <Link className="nav-link" to="#" onClick={handleMenuItemClick}>
                                        Pages
                                    </Link>
                                    <span className="drp_btn">
                                        <i className="icofont-rounded-down" />
                                    </span>
                                    <div className="sub_menu">
                                        <ul>
                                            <li>
                                                <Link to="/bloglist">Blog List </Link>
                                            </li>
                                            <li>
                                                <Link to="/blogdetails">Blog Details</Link>
                                            </li>
                                            <li>
                                                <Link to="/reserv1">reservation 1</Link>
                                            </li>
                                            <li>
                                                <Link to="/reviews">reviews</Link>
                                            </li>
                                            <li>
                                                <Link to="/gallery">gallery</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contactUs" onClick={handleMenuItemClick}>
                                        İLETİŞİM
                                    </Link>
                                </li>
                                <li className="nav-item contact_number">
                                    <Link className="nav-link" to="tel:+905334438636">
                                        <span>İLETİŞİM:</span> +90 505 595 49 29
                                    </Link>
                                </li>
                                {/* <li className="d-lg-none">
                                    <Link to="/reserv1" className="btn btn_primary">
                                        FIND A TABLE
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className="action_bar">
                            <a href="https://www.instagram.com/organizasyonseramoni/" target="_blank" rel="noopener noreferrer" className="btn btn_primary yellow_btn">
                                Instagram'da Takip Et
                            </a>
                            <div className="bar" onClick={() => setNav(true)}>
                                <span />
                                <span />
                                <span />
                            </div>
                        </div>

                    </nav>
                </div>
            </header>

            {Nav &&
                <div className={`right_sidemenu ${Nav && "active"}`}>
                    <span className="close_rightmenu" onClick={() => setNav(false)}>
                        <i className="icofont-close-circled" />
                    </span>
                    <div className="menu_inner_content">
                        <Link to="#" className="side_logo">
                            <img src={Logo} alt="image" />
                        </Link>
                        <ul className="contact_listing">
                            <li>
                                <p className="sub_title">Telefon</p>
                                <div className="cnt_block">
                                    <Link to="tel:+905055954929">+90 505 595 49 29</Link>
                                    <span>
                                        <i className="icofont-ui-call" />
                                    </span>
                                </div>
                                {/* <div className="cnt_block">
                                    <Link to="tel:+10987654321">+1 098 765 4321</Link>
                                    <span>
                                        <i className="icofont-whatsapp" />
                                    </span>
                                </div> */}
                            </li>
                            <li>
                                <p className="sub_title">Email</p>
                                <div className="cnt_block">
                                    <Link to="mailto:">info@seramoniorganizasyon.com</Link>
                                    <span>
                                        <i className="icofont-envelope-open" />
                                    </span>
                                </div>
                            </li>
                            <li>
                                <p className="sub_title">Adres</p>
                                <div className="cnt_block">
                                    <p>Taşyaka Mahallesi Ölüdeniz Caddesi Erasta Avm Karşısı, No:31/11, 48300 Fethiye/Muğla</p>
                                    <span>
                                        <i className="icofont-location-pin" />
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <Link to="/contactUs" className="btn btn_primary">
                            İLETİŞİM
                        </Link>
                        {/* <figure className="tab_img">
                            <img src={MenuImg} alt="img" />
                        </figure> */}
                    </div>
                </div>
            }
        </>
    )
}

export default Main