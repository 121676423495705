import React from 'react'
import Ban1 from '../../assets/images/abtbanshape.png'
import Icon1 from '../../assets/images/title_icon.png'
import Ban01 from '../../assets/images/abtban_01.png'
import Ban02 from '../../assets/images/abtban_02.png'
import Ban03 from '../../assets/images/abtban_03.png'
import Ban04 from '../../assets/images/abtban_04.png'
import Ban05 from '../../assets/images/abtban_05.png'
import Bg from '../../assets/images/overlay_bg.png'

function Banner() {
    return (
        <>
            <section className="abt_banner">
                <div className="right_shape position-absolute">
                    <img src={Ban1} alt="img" />
                </div>
                <div className="section_title text-center">
                    <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
                        {/* <img src={Icon1} alt="img" /> */}
                    </span>
                    <span className="sub_text mb-1" data-aos="fade-down" data-aos-duration={1500} >
  Bizim hikayemiz{" "}
</span>
<h2>ÖZEL ORGANİZASYONLARINIZ İÇİN MÜKEMMEL ÇÖZÜMLER</h2>
<p className="lead" data-aos="fade-up" data-aos-duration={1500}>
  Hizmetler:
  <div className="row">
    <div className="col-md-4">
      <ul>
        <li>Düğün</li>
        <li>Kına Gecesi</li>
        <li>Firma Açılış Organizasyonu</li>
        <li>Balon Süsleme</li>
        <li>Baby Shower</li>
        <li>Doğum Odası Süsleme</li>
        <li>Konser</li>
        <li>Ramazan Etkinlikleri</li>
        <li>İllüzyon Show</li>
      </ul>
    </div>
    <div className="col-md-4">
      <ul>
        <li>Yeni Nesil Bando</li>
        <li>Mehter Takımı</li>
        <li>Uzun Adam</li>
        <li>Tahta Bacak</li>
        <li>Transformers</li>
        <li>Pamuk Şeker</li>
        <li>Pop Corn</li>
        <li>Osmanlı Macunu</li>
        <li>Şerbet</li>
      </ul>
    </div>
    <div className="col-md-4">
      <ul>
        <li>Tanıtım Paneli</li>
        <li>Kongre</li>
        <li>Fuar Dizaynı</li>
        <li>Doğum Günü</li>
        <li>Evlilik Teklifi</li>
        <li>Evlilik Yıldönümü</li>
        <li>Sahil Nikah</li>
        <li>Beach Party</li>
        <li>Mevlüt</li>
      </ul>
    </div>
    <div className="col-md-4">
      <ul>
        <li>Yemek Organizasyonu</li>
        <li>Lokma Dökme & Dağıtma</li>
        <li>Trio Sanatçı Temini</li>
        <li>Doğa Yürüyüşü</li>
        <li>Çocuk Tiyatrosu</li>
        <li>Dansöz</li>
        <li>Kemancı</li>
        <li>Ahşap Masa & Sandalye</li>
        <li>Tonet Sandalye</li>
      </ul>
    </div>
    <div className="col-md-4">
      <ul>
        <li>Masa & Sandalye Kiralama</li>
        <li>Düğün Fotoğraf Dış Çekim</li>
        <li>Villa Çekimi</li>
        <li>Mankenli Tanıtım Çekimi</li>
        <li>Youtube Reklam Filmi Çekimi</li>
        <li>Tanıtım Filmi</li>
        <li>Açık Hava Sinema</li>
        <li>Sahne Işık</li>
        <li>Ses Sistemi</li>
      </ul>
    </div>
    <div className="col-md-4">
      <ul>
        <li>Bayi Toplantısı</li>
        <li>Dans & Folklor Ekibi</li>
        <li>Halloween Organizasyonu</li>
      </ul>
    </div>
  </div>
</p>


                </div>
                <div className="abt_img">
                    <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} >
                        <img src={Ban01} alt="img" />
                    </div>
                    <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={100} >
                        <img src={Ban02} alt="img" />
                    </div>
                    {/* <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                        <img src={Ban03} alt="img" />
                    </div> */}
                    <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={100} >
                        <img src={Ban04} alt="img" />
                    </div>
                    <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} >
                        <img src={Ban05} alt="img" />
                    </div>
                </div>
                <div className="ovelay_bg">
                    <img className="" src={Bg} alt="img" />
                </div>
            </section>

        </>
    )
}

export default Banner